import { QueryClient, useQuery } from "@tanstack/vue-query";
import QueryKeys from "@/vue-query/QueryKeys";

import { useProjectApi } from "../api/useProjectApi";
import { DeploymentSummaryDto } from "~/api/contracts/models";

export function useProjectDetailedDeploymentsQuery(projectId: Ref<string>) {
  const client = useProjectApi();
  return useQuery(
    [QueryKeys.DetailedDeployments, projectId],
    () => client.listDeploymentsWithDetails(projectId.value),
    {
      refetchInterval: 15 * 1000,
      enabled: projectId.value != "",
    }
  );
}

export function useProjectDetailedDeploymentsQueryPrefetch(
  queryClient: QueryClient,
  projectId: Ref<string>
): Promise<void> {
  const client = useProjectApi();
  return queryClient.prefetchQuery<DeploymentSummaryDto[]>(
    [QueryKeys.DetailedDeployments, projectId],
    () => client.listDeploymentsWithDetails(projectId.value)
  );
}
